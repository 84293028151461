import axios from 'axios'

import './schedule.css'

let count = 0;

function Story() {
    return (
        <div id="schedule" className="card">
            <h2>Schedule</h2>
            <div className="wrapper">
                <div className="body">
                    <span className="title">Time Table</span>
                    <table>
                        <tr>
                            <th>Day</th>
                            <th>Time EST (UTC-4)</th>
                        </tr>
                        <tr>
                            <td>Monday</td>
                            <td>Offline</td>
                        </tr>
                        <tr>
                            <td>Tuesday</td>
                            <td>4:30 PM</td>
                        </tr>
                        <tr>
                            <td>Wednesday</td>
                            <td>4:30 PM</td>
                        </tr>
                        <tr>
                            <td>Thursday</td>
                            <td>Offline</td>
                        </tr>
                        <tr>
                            <td>Friday</td>
                            <td>4:30 PM</td>
                        </tr>
                        <tr>
                            <td>Saturday</td>
                            <td>11:30 AM</td>
                        </tr>
                        <tr>
                            <td>Sunday</td>
                            <td>11:30 AM</td>
                        </tr>
                    </table>
                </div>
            </div>
        </div>
    )
}

export default Story;