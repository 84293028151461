import './card.css'
import axios from 'axios';
import profile from '../assets/img/profile.svg'

import {useEffect, useState} from "react";

let count = 0;

const defs = {
    followers: {
        twitch: 'followers',
        youtube: 'subscribers',
        discord: 'members online'
    },
    visit: {
        twitch: 'Visit',
        youtube: 'Visit',
        discord: 'Join'
    }
}

function Card(props) {
    const [avatar, setAvatar] = useState(profile);
    const [streaming, setStreaming] = useState(false);
    const [name, setName] = useState('n/a');
    const [followers, setFollowers] = useState('n/a');
    const [description, setDescription] = useState('n/a');
    const [link, setLink] = useState('#');
    const [items, setItems] = useState([]);

    useEffect(()=>{
        if (count === 0) {
            if (props.name === 'twitch') handleTwitch(setAvatar, setStreaming, setName, setFollowers, setDescription, setLink, setItems);
            if (props.name == 'youtube') handleYoutube(setAvatar, setName, setFollowers, setDescription, setLink, setItems);
            if (props.name == 'discord') handleDiscord(setAvatar, setName, setFollowers, setDescription, setLink, setItems);
            //count++;
        }
    }, [])

    return (
        <div className="card" id={props.name}>
            <h2 className="capitalize name unselectable" >{props.name}</h2>
            <div className="wrapper">
                <div className="head">
                    <a href={link} className="avatar unselectable">
                        <img src={ avatar } alt=""/>
                        <span className={streaming ? 'live' : 'hide'}>Live</span>
                    </a>
                    <div className="text">
                        <a href={link} className="username">{ name }</a>
                        <p className="user-count"><span className="count">{ followers }</span> {defs.followers[props.name]}</p>
                        <span className="desc">{ description }</span>
                    </div>
                    <a className="btn unselectable" href={link}>{defs.visit[props.name]}</a>
                </div>
                <div className="body">
                    {items.map((item) => (
                        <div key={item.key} className='item'>
                            {Object.keys(item).length !== 0 && <>
                                <p className="title unselectable">{ item.title }</p>
                                <a href={item.link  }><img className={item.image_class} alt="poster" src={item.image}></img></a>
                                <a href={item.link  }>{ item.name }</a>
                                {item.views && <p>{item.views} views</p>}
                                <p>{ item.date }</p>
                            </>}
                        </div>
                    ))}
                </div>
            </div>
        </div>
    )
}

async function handleTwitch(setAvatar, setStreaming, setName, setFollowers, setDescription, setLink, setItems) {
    setTimeout(async ()=> {
        let items = [];
        
        // Fetch data for Card
        const resp = await axios.get(process.env.REACT_APP_API + 'twitch')
        const data = resp.data;

        if (data.user.avatar) setAvatar(data.user.avatar);
        if (data.user.name) setName(data.user.name);
        if (data.user.followers) setFollowers(data.user.followers);
        if (data.user.description) setDescription(data.user.description);

        setLink('https://twitch.tv/' + data.user.login);
        setStreaming(data.stream);

        // Latest Stream
        items.push({
            key: data.latest_streams[0].id,
            title: "Last stream",
            image: data.latest_streams[0].thumbnail_url.replace("%{width}x%{height}", "320x180"),
            image_class: 'unselectable',
            name: data.latest_streams[0].title,
            date: new Date(data.latest_streams[0].published_at).toLocaleDateString().replaceAll('/', '-'),
            link: data.latest_streams[0].url
        });

        // Latest game
        items.push({
            key: data.user.game.id,
            title: "",
            image: data.user.game.poster,
            image_class: 'unselectable',
            name: data.user.game.name,
            link: 'https://www.twitch.tv/directory/game/' + data.user.game.name
        });

        // Empty
        items.push({})

        // Latest follower
        items.push({
            key: data.user.latest_follower.id,
            title: "Latest follower",
            image: data.user.latest_follower.avatar,
            image_class: "avatar unselectable",
            name: data.user.latest_follower.name,
            date: new Date(data.user.latest_follower.date).toLocaleDateString().replaceAll('/', '-'),
            link: 'https://twitch.tv/' + data.user.latest_follower.login
        })

        // Joined
        items.push({
            key: data.user.id,
            title: "Joined",
            image: "/img/twitch.webp",
            image_class: 'unselectable',
            date: new Date(data.user.created_at).toLocaleDateString().replaceAll('/', '-'),
        })

        setItems(items);
    })
}

async function handleYoutube(setAvatar, setName, setFollowers, setDescription, setLink, setItems) {
    setTimeout(async ()=> {
        let items = [];

        // Fetch data for Card
        const resp = await axios.get(process.env.REACT_APP_API + 'youtube')
        const data = resp.data;
        console.log(data)

        if (data.authorThumbnails) setAvatar(data.authorThumbnails[4].url);
        if (data.author) setName(data.author);
        if (data.subCount) setFollowers(data.subCount);
        if (data.description) setDescription(data.description);

        setLink(data.authorUrl);

        // Latest Videos
        for (let i = 0; i < 3; i++) {
            items.push({
                key: data.latestVideos[i].videoId,
                title: i === 0 ? "Latest Videos" : "",
                image: data.latestVideos[i].videoThumbnails[4].url,
                image_class: 'unselectable',
                name: data.latestVideos[i].title,
                views: data.latestVideos[i].viewCount,
                date: new Date(data.latestVideos[i].published*1000).toLocaleDateString().replaceAll('/', '-'),
                link: 'https://youtube.com/watch?v=' + data.latestVideos[i].videoId
            })
        }

        // Other Channel
        items.push({
            key: data.relatedChannels[0].authorId,
            title: "Other Channel",
            image: data.relatedChannels[0].authorThumbnails[5].url,
            image_class: "avatar unselectable",
            name: data.relatedChannels[0].author,
            link: 'https://youtube.com' + data.relatedChannels[0].authorUrl
        });

        // Joined
        items.push({
            key: data.authorId,
            title: "Joined",
            image: "/img/youtube.webp",
            image_class: 'unselectable',
            date: new Date(data.joined*1000).toLocaleDateString().replaceAll('/', '-'),
        })

        setItems(items);
    })
}

async function handleDiscord(setAvatar, setName, setFollowers, setDescription, setLink, setItems) {
    setTimeout(async ()=> {
        let items = [];

        // Fetch data for Card
        const resp = await axios.get(process.env.REACT_APP_API + 'discord')
        const data = resp.data;

        if (data.icon) setAvatar(data.icon);
        if (data.name) setName(data.name);
        if (data.members) setFollowers(`${data.online} of ${data.members}`);
        setDescription('Get notifications when I am live!\nI am trying to grow my discord so joining would be really helpful! Thanks!');

        setLink(data.invite);

        // Online Members
        let members = [];
        for (let i in data.online_members) { // Ignores the bots in the list
            if (['Chip', 'HyperBot', 'MEE6', 'Nightbot', 'RoVer', 'Auttaja'].includes(data.online_members[i].username)) continue;
            members.push(data.online_members[i]);
        }

        for (let i = 0; i < 5; i++) {
            items.push({
                key: members[i].id,
                title: i === 0 ? "Online Members" : "",
                image: members[i].avatar_url,
                image_class: 'avatar unselectable',
                date: members[i].username,
            })
        }

        setItems(items);
    })
}

export default Card