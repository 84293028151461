import Plyr from "plyr"
import axios from 'axios'

import './story.css'
import './plyr.css'
import stats from '../assets/img/stats.webp'
import {useEffect} from "react";

let count = 0;

function Story() {

    useEffect(()=>{
        if (count === 0) {
            handleVideo();
            handleModal();
            count++;
        }
    }, [])

    return (
        <div id="story" className="card">
            <h2>My Story</h2>
            <div className="wrapper">
                <div className="body">
                    <span className="title">The evolution of HyperGamingTW</span>
                    <div className="player-wrapper">
                        <video id="player" playsInline controls data-poster="#">
                            <source src="#" type="video/mp4"/>
                        </video>
                    </div>
                    <span className="title">Story</span>
                    <p>December 30, 2021, Is when I started streaming.
                        I decided to make my twitch account that day and I streamed for the first time.
                        For the next few days I streamed 30 minutes a day and I was having so much fun!
                        I used my Xbox web browser on twitch to get all of the correct settings.
                        I watched so many how to videos on streaming and I followed all of the steps of streaming.
                    </p>
                    <br/>
                    <p>On January 28th, 2021, I got my first follower and my first follower was not any of my friends or family it was the real deal.
                        When I first saw it after my stream I started celebrating I felt so powerful and strong.
                        Every once in while he would join my stream and chat a message or two, But then that led me into my first problem.
                        I had nothing to read chat with, and two people came into my stream before and said one chat message, but I could not read chat.
                        So I went down to my dad and I asked him if he had a spare computer or laptop and luck fully he did.
                        He had an old windows 7 Toshiba laptop and I still use it, It is a good machine but it was slow so I watch about 10 videos and I made the laptop twice the speed.
                        Once I could read chat nobody chatted for the next 4 months and I had no viewers.
                    </p>
                    <br/>
                    <p>Then one day I was watching some other small twitch streamers and I met dell_moon but his name was dellboy07 at the time and he had 3 viewers and about two people chatting and I started chatting to, I asked if I could play with him and it was Wednesday on a one weak weekend, And he said he plays with viewers on weekends so I watch the stream and chatted a lot before it was the weekend and I started playing with him we were playing ROBLOX and he knew I was streaming so he came over and one of his viewers did to.
                        The viewers name is darkslayer who is a very common viewer for me now. Over the course of the three past days I got 50 followers and I only had 17 before the time, and I had the best stats I have ever had, I thought they were so good that they would not get better but then they did on April 16th I was playing build a boat for treasure, and I put playing with viewers on the title and I filled an entire ROBLOX server with just my viewers. I hit a max of 15 viewers on that day and I usually saw the numbers 1 and 2 so I felt amazed, and I could not believe it and I got so many followers on that day and on that day I reached twitch affiliate.
                    </p>
                    <br/>
                    <p>over the past month my stats lowered but they stayed the same and slowly started growing until a month later to May and June I started making money, but it was not much, but it was something.
                        Then a few days after on June 1st somebody subscribed to me with prime and I made two dollars i was shaking and the person who did it was very nice, and I said thankyou about 100 times because that was first twitch subscriber, and it costs money to subscribe to people on twitch, so it was a big deal for me.
                        80% of my viewers are below 15 and they all say they would sub if their parents would let them.
                        Then that leads to present day and now I have pretty stable twitch stats and lots of viewers.
                    </p>
                    <br/>
                    <img className="unselectable" id="stats" src={stats} alt="Statistics"/>
                    <div id="modal" className="modal">
                        <img className="modal-content" id="img01"/>
                        <div id="caption"></div>
                    </div>
                </div>
            </div>
        </div>
    )
}

const blobToBase64 = blob => new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.readAsDataURL(blob);
    reader.onload = () => resolve(reader.result);
    reader.onerror = error => reject(error);
});

async function fetchBase64FromUrl(url) {
    const data = await axios.get(url, {
        responseType: 'blob'
    });

    return await blobToBase64(data.data);
}

async function assignVideo(player, data, misc) {

    const caption = new Blob([misc.caption.url]);
    const storyboard = new Blob([misc.storyboard]);

    // Assign player with new data
    player.source = {
        type: 'video',
        title: misc.title,
        sources: [
            {
                src: data.formatStreams[1].url,
                type: 'video/mp4',
                size: 360,
            },
            {
                src: data.formatStreams[2].url,
                type: 'video/mp4',
                size: 720,
            }

        ],
        quality: { 
            default: 720, 
            options: [360, 720] },
        poster: misc.poster,
        previewThumbnails: {
            enabled: true,
            src: URL.createObjectURL(storyboard),
        },
        tracks: [
            {
                kind: 'captions',
                label: misc.caption.label,
                srclang: misc.caption.language_code,
                src: URL.createObjectURL(caption),
                default: true,
            }
        ],
    }
}

async function handleVideo() {
    // Wait till frame has fully loaded
    setTimeout(async ()=> {
        const player = new Plyr('#player');


        // Specify wanted fields for fetch
        let fields = "formatStreams"
        let misc = {}

        const expired = (date) =>{
            const now = Date.now();
            return date < now;
        }

        // Check if video data is already saved
        if (localStorage.getItem('video') && !expired(JSON.parse(localStorage.getItem(('video'))).expire)) {
            misc = JSON.parse(localStorage.getItem('video'));
        } else {
            fields += ",videoThumbnails,captions,storyboards,title"
        }

        console.log()


        // Fetch data from Invidious
        const resp = await axios.get("https://inv.pigg.es/api/v1/videos/psw4v1leyIA?fields=" + fields)
        const data = resp.data;

        if (!localStorage.getItem('video') || expired(JSON.parse(localStorage.getItem(('video'))).expire)) {
            // Fetch misc and save to localStorage
            misc.caption = {
                'label': data.captions[0].label, 
                'language_code': data.captions[0].language_code, 
                'url': (await axios.get("https://inv.pigg.es" + data.captions[0].url)).data};
            misc.storyboard = (await axios.get("https://inv.pigg.es" + data.storyboards[1].url)).data;
            misc.poster = await fetchBase64FromUrl(data.videoThumbnails[0].url);
            misc.title = data.title;
            misc.expire = Date.now() + 1000*60*60*24;
            localStorage.setItem('video', JSON.stringify(misc))
        }


        assignVideo(player, data, misc)
    })
}

function handleModal() {
    // Wait till frame has fully loaded
    setTimeout(async ()=> {
        // Get the modal
        const modal = document.getElementById('modal');

        // When the user clicks on <span> (x), close the modal
        modal.onclick = function() {
            const modalImg = document.getElementById("img01");
            modalImg.className += " out";
            setTimeout(function() {
            modal.style.display = "none";
            modalImg.className = "modal-content";
            }, 400);
            
        }
        
        // Get the image and insert it inside the modal - use its "alt" text as a caption
        const img = document.getElementById('stats');
        const modalImg = document.getElementById("img01");
        const captionText = document.getElementById("caption");
        img.onclick = function(){
            modal.style.display = "block";
            modalImg.src = this.src;
            modalImg.alt = this.alt;
            captionText.innerHTML = this.alt;
        }
    })
}

export default Story;