import './hero.css'
import Typewriter from "typewriter-effect";
import avatar from "../assets/img/avatar.webp"

function Hero() {

    return (
        <div id="hero">
            <div className="left hide-phone">
                <span>
                    Hello, I'm <span style={{color: "var(--secondary)"}}>Hyper</span>.<br/>
                    I am a <span
                    className="unselectable">
                    <Typewriter
                        options={{
                            strings: words(),
                            autoStart: true,
                            loop: true,
                            skipAddStyles: false,
                        }}
                    />
                    </span>
                </span>
            </div>
            <div className="right">
                <div className="about">
                    <img className="unselectable" src={avatar} alt="Hyper"/>
                    <h2>About me</h2>
                    <span className="text">
                        Hey! you there, I'm HyperGamingTW and I am a small twitch streamer!
                        Come stop by sometime when I am live! In my streams we all have fun and play together!
                        I don't care about what my stream is like, I care about how much fun I'm having.
                        I need your help!
                    </span>
                </div>
            </div>
        </div>
    )
}

function words() {
    const list = [
        "streamer", "content creator", "gamer"
    ]
    for (const i in list) {
        list[i] = "<span style=\"color: var(--primary);\">"+list[i]+"</span>"+"."
    }
    return list;
}


export default Hero;