import './arrow.css'

function Arrow() {
    return (
        <div id="arrow">
            <a href="#twitch">
                <svg version="1.1" xmlns="http://www.w3.org/2000/svg" x="0px" y="0px" viewBox="0 0 1000 1000">
                    <g><path d="M10,103.9L67.6,45L500,487.1L932.3,45l57.7,58.9L500,605L10,103.9z M500,837.1L932.3,395l57.7,58.9L500,955L10,453.9L67.6,395L500,837.1z"/></g>
                </svg>
            </a>
        </div>
    )
}

export default Arrow