import './header.css'

function Header() {
    return (
        <header id="header">
            <div className="left">
                <a href="#" className="brand unselectable">Hyper</a>
                <svg onClick={handleMenu} version="1.1" className="hide-desktop" id="menu" xmlns="http://www.w3.org/2000/svg" x="0px" y="0px"
                     viewBox="0 0 384.97 384.97">
                    <g>
                        <path d="M12.03,84.212h360.909c6.641,0,12.03-5.39,12.03-12.03c0-6.641-5.39-12.03-12.03-12.03H12.03
                C5.39,60.152,0,65.541,0,72.182C0,78.823,5.39,84.212,12.03,84.212z"/>
                        <path d="M372.939,180.455H12.03c-6.641,0-12.03,5.39-12.03,12.03s5.39,12.03,12.03,12.03h360.909c6.641,0,12.03-5.39,12.03-12.03
                S379.58,180.455,372.939,180.455z"/>
                        <path d="M372.939,300.758H12.03c-6.641,0-12.03,5.39-12.03,12.03c0,6.641,5.39,12.03,12.03,12.03h360.909
                c6.641,0,12.03-5.39,12.03-12.03C384.97,306.147,379.58,300.758,372.939,300.758z"/>
                    </g>
                </svg>
            </div>
            <div id="links" className="right hide-phone">
                <a className="active" href="#">Home</a>
                <a href="#twitch">Twitch</a>
                <a href="#youtube">Youtube</a>
                <a href="#discord">Discord</a>
                <a href="#story">My story</a>
                <a href="#schedule">Schedule</a>
            </div>
        </header>
    );
}


function handleMenu(e) {
    if (!e) return;
    const menu = document.querySelector('#menu');
    const links = document.querySelector('#links');
    if (links.classList.contains('hide-phone')) {
        links.classList.remove('hide-phone');
        menu.style.transform = 'rotate(90deg)';
    } else {
        links.classList.add('hide-phone');
        menu.style.transform = 'rotate(0deg)';
    }
}

export default Header;