import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import Header from './header/header';
import Hero from './hero/hero';
import Arrow from './arrow/arrow';
import Card from './card/card'
import Story from "./story/story";
import Schedule from "./schedule/schedule";

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <React.StrictMode>
      <Header/>
        <div className="hero">
          <Hero/>
          <Arrow/>
        </div>
      <main>
          <Card name="twitch"/>
          <Card name="youtube"/>
          <Card name="discord"/>
          <Story/>
          <Schedule/>
      </main>
  </React.StrictMode>
);